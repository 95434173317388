import React from "react";
import { graphql } from 'gatsby';

import Layout from "templates/basic/about-us";
import EdgeBlock from 'components/edgeblock/';
// import Blockquote from 'components/blockquote/';
import SEO from 'components/seo/';
import Accordion from 'components/accordion/';
import contentStyle from 'templates/ContentPageGet/ContentPageGet.module.scss';
import style from './about-us.module.scss';
import Intro from 'components/intro'
import parse from 'html-react-parser';
import SectionCallout from "../../components/sectioncallout";


const AboutUs = ({ data, pageContext }) => {

	let page = null;
	let legacies = null;
	let pvvcs = null;

	if (data.allContentPage && data.allContentPage.edges.length) page = data.allContentPage.edges[0].node;

	if (data.allAboutLegacy && data.allAboutLegacy.edges.length) legacies = data.allAboutLegacy.edges;

	if (data.allAboutPvvc && data.allAboutPvvc.edges.length) pvvcs = data.allAboutPvvc.edges;

	return (
		<>
			<Layout image={page.headerImage} preTitle={page.pageTitle} title={page.subTitle} link={page.link} linkText={page.linkText} enableDanosFoundation={page.danosFoundation} enableFooterTabs={page.danosFooterTab}>
				<Intro pullQuote={page.pullQuote} headline={page.headline} pageCopy={page.pageCopy} />
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12">
							<hr style={{ margin: "60px auto 0px" }} />
						</div>
					</div>
				</div>
				{page.content.main &&
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12">
								<hr style={{ margin: "60px auto" }} />
								<div className={contentStyle.innerContent}>
									{parse((page ? page.content.main : ''))}
								</div>
							</div>
						</div>
					</div>
				}

				<section id="legacy" className={style.featuredContentWrapper}>
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12 large-8">
								<h3>History - A legacy of results.</h3>
								<p className={style.subText}>Throughout the past seven decades, one thing has remained the same at Danos: our unfaltering commitment to values, safety and overall results for our clients.</p>
							</div>
							<div className={`${style.accordionWrapper} cell small-12 large-8`}>
								{legacies && legacies.map(({ node }, index) => <Accordion key={index} title={node.title} body={node.content} />)}
							</div>
						</div>
					</div>
				</section>

				<section id="pvvc" className={style.pvvc}>
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12 large-6">
								<h3>Purpose, Values, Vision &amp; Culture</h3>
								<p className={style.subText}>Founded in 1947, Danos is a family-owned and managed oilfield service provider. A trusted industry partner, Danos offers the most responsive end-to-end integrated service solutions – safely, on time and within budget. Danos achieves world-class safety results and customer loyalty due to a values-based approach and an unyielding commitment to employee engagement and training.</p>

								<p className={style.subText}>At Danos, our purpose, values and high performance culture traits are fundamental to how we do business. It is our road map to meeting the performance goals of every customer. From our corporate office to every platform and well pad we serve, the Danos team is committed to these core beliefs.</p>
							</div>
							<div className="cell small-12 medium-offset-1 medium-5">
								<EdgeBlock>We hire and promote employees who demonstrate the six high-performance traits that align with the Danos culture. This ensures our customers can trust that the Danos team knows how to deliver results.</EdgeBlock>
							</div>
						</div>
					</div>
					<div className={style.spacer}></div>
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12">
								{pvvcs && pvvcs.map(({ node }, index) => <><SectionCallout key={`${index}-pvvc`} data={node.cards} arrows={node.cards.length > 1} flip={index % 2 === 0} minHeightNum={371} /><div className={style.pvvcsSpacer} /></>)}
							</div>
						</div>
					</div>
				</section>
			</Layout>
			<SEO />
		</>
	)
}

export default AboutUs;

export const query = graphql`
	query ($lang: String) {
		allContentPage(filter: {uri: {eq: "/about-us/"}, lang: {eq: $lang}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			edges {
				node {
					id
					uri
					content {
						main
					}
					lang
					pageTitle
					subTitle
					pullQuote
					pageCopy
					headline
					link
					linkText
					danosFoundation
					danosFooterTab
					headerImage
				}
			}
		}
		allAboutLegacy(sort: {fields: sort, order: ASC}, filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			edges {
			  node {
				title
				content
			  }
			}
		  }
		  allAboutPvvc(sort: {fields: sort, order: ASC}, filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
			edges {
			  node {
				cards
			  }
			}
		  }
	}
`